import React from 'react';

const Footer = () => {
  return (
    <>
      <hr className="--color-dark"/>
      
      <div className="--flex-center --py2 --bg-grey">
       <p>All Rights Reserved. &copy; 2023</p>
      </div>

    </>
  )
}

export default Footer
